import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { QAS, SERVER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import LogQuestions from '../../LogQuestions/LogQuestions';
import Categories from '../../Categories/Categories';
import Channels from '../../Channels/Channels';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';

const QuestionTags = (): JSX.Element => {
	const [showLog, setShowLog] = useState<boolean>(false); // показ вкладки логов массовой обработки вопросов
	const [showCategories, setShowCategories] = useState<boolean>(false); // показ вкладки категорий
	const [showChannels, setShowChannels] = useState<boolean>(false); // показ вкладки каналов
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(QAS.PROCESSING_QUESTIONS_LOG) &&
						<Tag setShowTag={setShowLog} tagName='tag_processingLogs' smallTitleSize />
					}
					{isAccess(QAS.CATEGORY_LIST) &&
						<Tag setShowTag={setShowCategories} tagName='tag_categories' />
					}
					{isAccess(QAS.CHANNEL_LIST) &&
						<Tag setShowTag={setShowChannels} tagName='tag_channels' />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<LogQuestions showLog={showLog} setShowLog={setShowLog} />
			<Categories showCategories={showCategories} setShowCategories={setShowCategories} />
			<Channels showChannels={showChannels} setShowChannels={setShowChannels} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default QuestionTags;
