import { useState } from 'react';
import { Document, Packer, Paragraph, SectionType, TextRun } from "docx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { downloadFile } from '../../../../helpers/downloadFile';
import { backgroundColor } from '../../../../constants/colors';
import FormExportDocFile from '../../../Forms/FormExportDocFile/FormExportDocFile';
import { IExportToDocProps } from './ExportToDoc.props';

const ExportToDoc = ({ isAvailable, fileName, data, speakerList }: IExportToDocProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы выбора типа документа

	const translate = useTranslate(); // hook для перевода текста

	// обработчик экспорта в .docx
	const exportDocxFileHandler = (): void => {
		let doc: Document;

		if (data.type === 'dialog') {
			// создаем документ диалога
			doc = new Document({
				sections: data.data.map(fragment => {
					return {
						properties: { type: SectionType.CONTINUOUS }, // продолжение новой секции следом за предыдущей
						children: [
							// автор
							new Paragraph({
								children: [
									new TextRun({
										text: typeof fragment.speaker === 'number' ? speakerList[fragment.speaker] : `${fragment.speaker}`,
										bold: true,
										// color: colorSecondary,
										font: 'Roboto',
									}),
								],
							}),
							// текст
							new Paragraph({
								children: fragment.words.map(word => {
									return new TextRun({
										text: word.word + ' ',
										// color: (word.confidence && word.confidence <= 0.5) ? colorRed : colorPrimary,
										font: 'Roboto',
									});
								}),
							}),
							// отступ
							new Paragraph({}),
						],
					};
				}),
			});
		} else {
			// создаем документ монолога
			doc = new Document({
				sections: [{
					children: [
						// автор
						new Paragraph({
							children: [
								new TextRun({
									text: 'unknown',
									bold: true,
									// color: colorSecondary,
									font: 'Roboto',
								}),
							],
						}),
						// текст
						new Paragraph({
							children: data.data.map(word => {
								return new TextRun({
									text: word.word + ' ',
									// color: (word.confidence && word.confidence <= 0.5) ? colorRed : colorPrimary,
									font: 'Roboto',
								});
							}),
						}),
					],
				}],
			});
		}

		// экспорт .docx
		Packer.toBlob(doc).then((blob) => {
			downloadFile(blob, fileName + '.docx');
		}).catch(e => console.error(e));
	};

	// обработчик экспорта в .txt
	const exportTxtFileHandler = (): void => {
		let doc: string = '';

		if (data.type === 'dialog') {
			data.data.forEach(fragment => {
				const author = typeof fragment.speaker === 'number' ? speakerList[fragment.speaker] : `${fragment.speaker}`;
				const replica = fragment.words.map(word => word.word).join(' ');
				doc += author + '\n' + replica + '\n\n';
			});
		} else {
			const author = 'unknown';
			const replica = data.data.map(word => word.word).join(' ');
			doc += author + '\n' + replica + '\n\n';
		}

		downloadFile(doc, fileName + '.txt');
	};

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_exportDocFile')}>
				<FontAwesomeIcon icon={faFileWord} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormExportDocFile
					showModal={showModal}
					setShowModal={setShowModal}
					exportDocxFileHandler={exportDocxFileHandler}
					exportTxtFileHandler={exportTxtFileHandler}
				/>
			}
		</>
	);
};

export default ExportToDoc;
