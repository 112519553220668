import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearQuestionManipulation, getQuestionsList, selectApproveCandidateList } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import ScreenLock from '../../ScreenLock/ScreenLock';
import Notification from '../../Notification/Notification';
import { ICandidatesControlsProps } from './CandidatesControls.props';

const CandidatesControls = ({ setShowPage }: ICandidatesControlsProps): JSX.Element => {
	const [showNotificationApprove, setShowNotificationApprove] = useState<boolean>(false); // показ уведомления утверждения кандидатов

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const approveCandidateList = useAppSelector(selectApproveCandidateList); // store - статус утверждения кандидатов

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом утверждения кандадитов
	useEffect(() => {
		if (approveCandidateList.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: approveCandidateList.deleteOnly ? 'spinnerTitle_deletion' : 'spinnerTitle_approving' }); // включаем экран блокировки
			setShowNotificationApprove(true); // включаем уведомление
		}
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если утверждение кандидатов прошло успешно
		if (approveCandidateList.status === RequestStatus.IDLE && approveCandidateList.error === ResponseStatus.SUCCESS && approveCandidateList.message !== '') dispatch(getQuestionsList({})); // получиаем заново список вопросов
	}, [approveCandidateList]);

	return (
		<>
			<Controls
				header='qas'
				wideHeader
				setShowPage={setShowPage}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для утверждения кандидата */}
			{showNotificationApprove &&
				<Notification
					showNotification={showNotificationApprove}
					setShowNotification={setShowNotificationApprove}
					selectDataResponse={selectApproveCandidateList}
					clearDataResponse={clearQuestionManipulation}
					titleFailed='noticeApproval_failed'
					titleSuccess='noticeApproval_success'
				/>
			}
		</>
	);
};

export default CandidatesControls;
