import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAnswerMakerStatus, clearDeletingQuestion, clearEditingQuestion, clearProcessingQuestionsStatus, clearQuestionsList, deleteQuestion, deleteQuestionsList, editingQuestionInQuestionsList, editQuestion, getQuestion, getQuestionsList, selectDeletingQuestion, selectEditingQuestion, selectFilter, selectGeneratingAnswersStopStatus, selectProcessingQuestionsStopStatus, selectQuestion, selectQuestionsList, specifyDeletionOnlyAnswers } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { AUTOSAVE_QUESTION, QAS_QUESTION_ID } from '../../../constants/cookieNames';
import { DeleteQuestionsListTypes, IQuestionItem } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import ToggleCheck from '../Buttons/ToggleCheck/ToggleCheck';
import MovingQuestions from '../Buttons/MovingQuestions/MovingQuestions';
import Delete from '../Buttons/Delete/Delete';
import ExportQuestions from '../Buttons/ExportQuestions/ExportQuestions';
import ToggleAutosave from '../Buttons/ToggleAutosave/ToggleAutosave';
import Update from '../Buttons/Update/Update';
import Generating from '../Buttons/Generating/Generating';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IQuestionControlsProps } from './QuestionControls.props';

const QuestionControls = ({ changeFlg, setChangeFlg, setShowPage, inputQuestion, selectCategoryId, incompleteQuestionFlg, showAlertDialogDelete, setShowAlertDialogDelete, highlightMultipleQuestions, setHighlightMultipleQuestions, selectedListQuestionIds }: IQuestionControlsProps): JSX.Element => {
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления вопроса
	const [showNotificationMove, setShowNotificationMove] = useState<boolean>(false); // показ уведомления удаления (перемещения)

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const question = useAppSelector(selectQuestion); // store - вопрос/ответ
	const editStatus = useAppSelector(selectEditingQuestion); // store - статус сохранения ответов
	const deletionStatus = useAppSelector(selectDeletingQuestion); // store - статус удаления вопроса
	const stopGenerationStatus = useAppSelector(selectGeneratingAnswersStopStatus); // store - статус остановки генерации ответов
	const stopProcessingQuestionsStatus = useAppSelector(selectProcessingQuestionsStopStatus); // store - статус остановки массовой обработки вопросов

	const [_cookies, setCookie] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления вопроса, остановки генерации ответов/массовой обработки вопросов
	useEffect(() => {
		if (editStatus.status === RequestStatus.LOADING && !editStatus.autosave) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' }); // если идет сохранение ответов (не автоматическое)
		else if (deletionStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление вопроса
		else if (stopGenerationStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_stop' }); // если идет остановка генерации ответов
		else if (stopProcessingQuestionsStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_stop' }); // если идет остановка массовой обработки вопросов
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранение прошло успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message !== '') {
			setChangeFlg(false); // выключаем флаг
			// поиск вопроса в списке вопросов
			const foundQuestion = questionsList.data.find(questionItem => questionItem.id === question.questionId);
			// если изменился текст вопроса/категория/флаг неполного вопроса
			if (foundQuestion && (foundQuestion?.question !== inputQuestion || foundQuestion.category !== selectCategoryId || foundQuestion.incomplete !== incompleteQuestionFlg)) {
				dispatch(editingQuestionInQuestionsList({
					id: foundQuestion.id,
					question: inputQuestion,
					aliases: foundQuestion.aliases,
					candidates: foundQuestion.candidates,
					category: selectCategoryId,
					checked: foundQuestion.checked,
					unchecked: foundQuestion.unchecked,
					incomplete: incompleteQuestionFlg,
				})); // меняем вопрос в списке вопросов
			} else {
				editStatus.autosave && dispatch(clearEditingQuestion()); // очищаем статус (для режима автосохранения)
			}
		}
		// если удаление прошло успешно
		if (deletionStatus.status === RequestStatus.IDLE && deletionStatus.error === ResponseStatus.SUCCESS && deletionStatus.message !== '') {
			// если удалялись вопрос/вопросы
			if (!deletionStatus.deletingAnswers) {
				const foundIdxDeleteQuestion = questionsList.data.filter(filterFunction).findIndex(questionItem => questionItem.id === question.questionId); // idx удаленного вопроса
				// если найден удаленный вопрос и в списке больше одного вопроса
				if (foundIdxDeleteQuestion >= 0 && questionsList.data.filter(filterFunction).length > 1) {
					// id предыдущего вопроса, если удаляемый вопрос был последним, или следующего 
					const questionId = questionsList.data.filter(filterFunction).length - 1 === foundIdxDeleteQuestion ?
						questionsList.data.filter(filterFunction)[foundIdxDeleteQuestion - 1].id
						:
						questionsList.data.filter(filterFunction)[foundIdxDeleteQuestion + 1].id;
					setCookie(QAS_QUESTION_ID, questionId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
				}
			}
			dispatch(clearQuestionsList()); // очищаем список вопросов
			dispatch(getQuestionsList({ answer: filter.answer, question: filter.question })); // получаем заново список вопросов
			setHighlightMultipleQuestions(false); // убираем выделение вопросов
		}
		// если остановка генерации ответов прошла успешно - очищаем статус процесса генерации
		if (stopGenerationStatus.status === RequestStatus.IDLE && stopGenerationStatus.error === ResponseStatus.SUCCESS && stopGenerationStatus.message !== '') dispatch(clearAnswerMakerStatus());
		// если остановка массовой обработки вопросов прошла успешно - очищаем статус процесса
		if (stopProcessingQuestionsStatus.status === RequestStatus.IDLE && stopProcessingQuestionsStatus.error === ResponseStatus.SUCCESS && stopProcessingQuestionsStatus.message !== '') dispatch(clearProcessingQuestionsStatus());
	}, [editStatus, deletionStatus, stopGenerationStatus, stopProcessingQuestionsStatus]);

	// функция фильтрации списка вопросов
	const filterFunction = (questionItem: IQuestionItem): boolean => {
		return (questionItem.category === filter.category || filter.category === '') &&
			((filter.answerType === 'all' && (questionItem.checked >= 0 || questionItem.unchecked >= 0)) ||
				(filter.answerType === 'checked' && questionItem.checked > 0) ||
				(filter.answerType === 'unchecked' && questionItem.unchecked > 0) ||
				(filter.answerType === 'empty' && (questionItem.checked === 0 && questionItem.unchecked === 0))
			);
	};

	// обработчик удаления вопроса
	const deleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		question.questionId && dispatch(deleteQuestion(question.questionId)); // удаление вопроса
	};

	// обработчик удаления нескольких вопросов
	const deleteMultipleHandler = (type: DeleteQuestionsListTypes): void => {
		type === 'answers' && dispatch(specifyDeletionOnlyAnswers()); // уточнение - удаляем только ответы
		dispatch(deleteQuestionsList({ questionsIdList: selectedListQuestionIds, type })); // удаляем вопросы или ответы
	};

	return (
		<>
			<Controls
				header='qas'
				wideHeader
				setShowPage={setShowPage}
				leftSection={
					<>
						<div>
							{isAccess(QAS.QUESTION_DELETE) &&
								<>
									<ToggleCheck
										isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
										highlightMultipleQuestions={highlightMultipleQuestions}
										setHighlightMultipleQuestions={setHighlightMultipleQuestions}
									/>
									{selectedListQuestionIds.length > 0 && isAccess(QAS.QUESTION_APPEND) &&
										<MovingQuestions
											isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING}
											selectedListQuestionIds={selectedListQuestionIds}
											setShowNotification={setShowNotificationMove}
										/>
									}
									{selectedListQuestionIds.length > 0 &&
										<Delete
											isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING}
											dataResponse={selectDeletingQuestion}
											clearDataResponse={clearDeletingQuestion}
											submitHandler={() => { }}
											submitTripleChoiceHandler={deleteMultipleHandler}
											buttonTitle='buttonTitle_deleteSelectedQuestions'
											dialogTitle='dialog_deleteMultipleQuestion'
											dialogConfirm='dialog_deleteMultipleQuestionConfirm'
										/>
									}
								</>
							}
						</div>
						<div>
							<ExportQuestions
								isAvailable={questionsList.data.filter(filterFunction).length > 0}
								filteredQuestionsList={questionsList.data.filter(filterFunction)}
							/>
							{isAccess(QAS.QUESTION_EDIT) &&
								<ToggleAutosave
									isAvailable={true}
									cookieName={AUTOSAVE_QUESTION}
									changeFlg={changeFlg}
									saveFunction={() => {
										question.questionId && dispatch(editQuestion({
											questionId: question.questionId,
											answers: question.answers,
											question: inputQuestion,
											aliases: question.aliases,
											candidates: question.candidates,
											categoryId: selectCategoryId,
											incomplete: incompleteQuestionFlg,
											autosave: true,
										})); // изменение вопросов и ответов
									}}
								/>
							}
						</div>
					</>
				}
				rightSection={
					<div>
						{isAccess(QAS.QUESTION_GET) &&
							<Update
								isAvailable={questionsList.data.filter(filterFunction).length > 0 && question.status !== RequestStatus.LOADING && question.questionId !== null}
								title='buttonTitle_updateQuestion'
								handler={() => {
									if (question.questionId) {
										dispatch(getQuestion(question.questionId));
										setChangeFlg(false);
									}
								}}
							/>
						}
						{isAccess([QAS.ANSWER_MAKER_START, QAS.ANSWER_MAKER_STOP, QAS.ANSWER_MAKER_STATUS]) &&
							<Generating
								isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
								generate='answers'
							/>
						}
						{isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) &&
							<Generating
								isAvailable={questionsList.status === RequestStatus.IDLE && question.status !== RequestStatus.LOADING}
								generate='all'
							/>
						}
					</div>
				}
			/>

			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteHandler}
					title='dialog_deleteQuestion'
					description='dialog_deleteQuestionConfirm'
				/>
			}

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для удаления вопроса */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletingQuestion}
					clearDataResponse={clearDeletingQuestion}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
			{/* уведомление для перемещения вопроса */}
			{showNotificationMove &&
				<Notification
					showNotification={showNotificationMove}
					setShowNotification={setShowNotificationMove}
					selectDataResponse={selectDeletingQuestion}
					clearDataResponse={clearDeletingQuestion}
					titleFailed='noticeMoving_failed'
					titleSuccess='noticeMoving_success'
				/>
			}
		</>
	);
};

export default QuestionControls;
