import { useEffect, useState } from 'react';
import { faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAddingData, clearAddingEndpoint, clearDataList, clearDeletingData, clearDeletingEndpoint, clearEditingData, clearEditingEndpoint, clearEndpointList, getDataList, getEndpointList, selectAddingData, selectAddingEndpoint, selectDeletingData, selectDeletingEndpoint, selectEditingData, selectEditingEndpoint } from '../../../store/sesSlice';
import { clearDeletingRobot, clearEditingRobot, clearRobotList, selectCommitConfigRobot, selectDeletingRobot, selectEditingRobot, selectRobot, getRobotList, selectActiveRobotId, selectActiveRobotVersion, changeActiveRobotVersion, applyRobot, restoreRobot, clearApplyingRobot, selectApplyingRobot, selectRestorationRobot, selectClearDraftingRobot, clearRestorationRobot, changeRobotPossibleVersionsInList, getRobot, clearRobotExceptVersions, clearExportRobot, selectExportRobot, exportRobot, selectImportRobot, clearImportRobot, clearCommitConfigRobot, commitConfigRobot } from '../../../store/sesRobotSlice';
import { clearAddingScript, clearDeletingScript, clearEditingScript, getScriptList, selectAddingScript, selectDeletingScript, selectEditingScript } from '../../../store/sesScriptSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import Version from '../Buttons/Version/Version';
import Export from '../Buttons/Export/Export';
import Import from '../Buttons/Import/Import';
import Apply from '../Buttons/Apply/Apply';
import Restore from '../Buttons/Restore/Restore';
import ClearDraftRobot from '../Buttons/ClearDraftRobot/ClearDraftRobot';
import ScreenLock from '../../ScreenLock/ScreenLock';
import Notification from '../../Notification/Notification';
import { IRobotsControlsProps } from './RobotsControls.props';

const RobotsControls = ({ setShowPage }: IRobotsControlsProps): JSX.Element => {
	const [showNotificationEditRobot, setShowNotificationEditRobot] = useState<boolean>(false); // показ уведомления об изменении данных робота
	const [showNotificationDelRobot, setShowNotificationDelRobot] = useState<boolean>(false); // показ уведомления об удалении робота

	const [showNotificationAddDataElem, setShowNotificationAddDataElem] = useState<boolean>(false); // показ уведомления о добавлении элемента данных
	const [showNotificationEditDataElem, setShowNotificationEditDataElem] = useState<boolean>(false); // показ уведомления об изменении элемента данных
	const [showNotificationDelDataElem, setShowNotificationDelDataElem] = useState<boolean>(false); // показ уведомления об удалении элемента данных

	const [showNotificationAddEndpoint, setShowNotificationAddEndpoint] = useState<boolean>(false); // показ уведомления о добавлении конечной точки
	const [showNotificationEditEndpoint, setShowNotificationEditEndpoint] = useState<boolean>(false); // показ уведомления об изменении конечной точки
	const [showNotificationDelEndpoint, setShowNotificationDelEndpoint] = useState<boolean>(false); // показ уведомления об удалении конечной точки

	const [showNotificationAddScript, setShowNotificationAddScript] = useState<boolean>(false); // показ уведомления о добавлении скрипта
	const [showNotificationEditScript, setShowNotificationEditScript] = useState<boolean>(false); // показ уведомления об изменении скрипта
	const [showNotificationDelScript, setShowNotificationDelScript] = useState<boolean>(false); // показ уведомления об удалении скрипта

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const applyingRobot = useAppSelector(selectApplyingRobot); // store - статус о применении черновика робота в прод
	const restorationRobot = useAppSelector(selectRestorationRobot); // store - статус о восстановлении робота в прод из бэкапа
	const clearDraftingRobot = useAppSelector(selectClearDraftingRobot); // store - статус обнуления черновика робота продом

	const editingRobot = useAppSelector(selectEditingRobot); // store - статус изменения данных робота
	const deletingRobot = useAppSelector(selectDeletingRobot); // store - статус удаления робота
	const commitConfigRobotStatus = useAppSelector(selectCommitConfigRobot); // store - применения настроек робота

	const addingDataElem = useAppSelector(selectAddingData); // store - статус добавления элемента данных
	const editingDataElem = useAppSelector(selectEditingData); // store - статус изменения элемента данных
	const deletingDataElem = useAppSelector(selectDeletingData); // store - статус удаления элемента данных

	const addingEndpoint = useAppSelector(selectAddingEndpoint); // store - статус добавления конечной точки
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const deletingEndpoint = useAppSelector(selectDeletingEndpoint); // store - статус удаления конечной точки

	const addingScript = useAppSelector(selectAddingScript); // store - статус добавления скрипта
	const editingScript = useAppSelector(selectEditingScript); // store - статус изменения скрипта
	const deletingScript = useAppSelector(selectDeletingScript); // store - статус удаления скрипта

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления/применения настроек/применения черновика/восстановления из бэкапа/обнуления черновика робота, добавления/изменения/удаления элемента данных, добавления/изменения/удаления конечной точки, добавления/изменения/удаления скрипта
	useEffect(() => {
		if (editingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditRobot(true);
		} // если идет изменение данных робота
		else if (deletingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelRobot(true);
		} // если идет удаление робота
		else if (commitConfigRobotStatus.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_applying' });
		} // если идет применение настроек робота
		else if (applyingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_applying' });
		} // если идет применение черновика в прод
		else if (restorationRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_recovery' });
		} // если идет восстановление робота из бэкапа
		else if (clearDraftingRobot.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_clearingDraft' });
		} // если идет обнуление черновика продовым роботом
		else if (addingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddDataElem(true);
		} // если идет добавление элемента данных
		else if (editingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditDataElem(true);
		} // если идет изменение элемента данных
		else if (deletingDataElem.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelDataElem(true);
		} // если идет удаление элемента данных
		else if (addingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddEndpoint(true);
		} // если идет добавление конечной точки
		else if (editingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditEndpoint(true);
		} // если идет изменение конечной точки
		else if (deletingEndpoint.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelEndpoint(true);
		} // если идет удаление конечной точки
		else if (addingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' });
			setShowNotificationAddScript(true);
		} // если идет добавление скрипта
		else if (editingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
			setShowNotificationEditScript(true);
		} // если идет изменение скрипта
		else if (deletingScript.status === RequestStatus.LOADING) {
			setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' });
			setShowNotificationDelScript(true);
		} // если идет удаление скрипта
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если удаление робота прошло успешно
		if (deletingRobot.status === RequestStatus.IDLE && deletingRobot.error === ResponseStatus.SUCCESS && deletingRobot.message !== '') {
			dispatch(clearRobotList()); // очищаем список роботов
			isAccess(SES.ROBOT_LIST) && dispatch(getRobotList()); // получаем заново список роботов
		}
		// если применение черновика робота прошло успешно
		if (applyingRobot.status === RequestStatus.IDLE && applyingRobot.error === ResponseStatus.SUCCESS && applyingRobot.message !== '') {
			dispatch(changeRobotPossibleVersionsInList({
				hasProd: true,
				hasBackup: robotInfo.hasProd ? true : false,
			})); // включаем hasProd и hasBackup, если был hasProd
			dispatch(changeActiveRobotVersion('prod')); // переходим на продовую версию
		}
		// если восстановление прода робота из бэкапа прошло успешно
		if (restorationRobot.status === RequestStatus.IDLE && restorationRobot.error === ResponseStatus.SUCCESS && restorationRobot.message !== '') {
			dispatch(changeRobotPossibleVersionsInList({ hasBackup: false })); // выключаем hasBackup
			dispatch(changeActiveRobotVersion('prod')); // переходим на продовую версию
		}
		// если обнуление черновика робота продом прошло успешно
		if (clearDraftingRobot.status === RequestStatus.IDLE && clearDraftingRobot.error === ResponseStatus.SUCCESS && clearDraftingRobot.message !== '') {
			// обновляем данные
			robotInfo.data && dispatch(clearRobotExceptVersions()); // очищаем данные о роботе, если есть
			isAccess(SES.ROBOT_GET) && activeRobotId && dispatch(getRobot(activeRobotId)); // получаем инфо о роботе
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем список элементов данных
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем список конечных точек
			isAccess(SES.SCRIPT_LIST) && activeRobotId && dispatch(getScriptList(activeRobotId)); // получаем список скриптов
		}

		// если добавление элемента данных прошло успешно
		if (addingDataElem.status === RequestStatus.IDLE && addingDataElem.error === ResponseStatus.SUCCESS && addingDataElem.message !== '') {
			dispatch(clearDataList()); // очищаем список элементов
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем заново список элементов
		}
		// если удаление элемента данных прошло успешно
		if (deletingDataElem.status === RequestStatus.IDLE && deletingDataElem.error === ResponseStatus.SUCCESS && deletingDataElem.message !== '') {
			dispatch(clearDataList()); // очищаем список элементов
			isAccess(SES.DATA_LIST) && activeRobotId && dispatch(getDataList(activeRobotId)); // получаем заново список элементов
		}

		// если добавление конечной точки прошло успешно
		if (addingEndpoint.status === RequestStatus.IDLE && addingEndpoint.error === ResponseStatus.SUCCESS && addingEndpoint.message !== '') {
			dispatch(clearEndpointList()); // очищаем список конечных точек
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем заново список конечных точек
		}
		// если удаление конечной точки прошло успешно
		if (deletingEndpoint.status === RequestStatus.IDLE && deletingEndpoint.error === ResponseStatus.SUCCESS && deletingEndpoint.message !== '') {
			dispatch(clearEndpointList()); // очищаем список конечных точек
			isAccess(SES.ENDPOINT_LIST) && activeRobotId && dispatch(getEndpointList(activeRobotId)); // получаем заново список конечных точек
		}
	}, [editingRobot, deletingRobot, commitConfigRobotStatus, applyingRobot, restorationRobot, clearDraftingRobot, addingDataElem, editingDataElem, deletingDataElem, addingEndpoint, editingEndpoint, deletingEndpoint, addingScript, editingScript, deletingScript]);

	// обработчик экспорта робота
	const exportHandler = (): void => {
		activeRobotId && robotInfo.data?.name && dispatch(exportRobot({ robotId: activeRobotId, robotName: robotInfo.data.name })); // экспорт робота
	};

	// обработчик применения черновика робота в прод
	const applyRobotHandler = (): void => {
		activeRobotId && dispatch(applyRobot(activeRobotId));
	};

	// обработчик восстановления робота прода из бэкапа
	const restoreRobotHandler = (): void => {
		activeRobotId && dispatch(restoreRobot(activeRobotId));
	};

	// обработчик применения настроек робота
	const applyConfigHandler = (): void => {
		activeRobotId && dispatch(commitConfigRobot(activeRobotId));
	};

	return (
		<>
			<Controls
				header='ses'
				setShowPage={setShowPage}
				leftSection={
					<div>
						<Version
							isAvailable={activeRobotId !== null && activeRobotVersion !== 'draft' && robotInfo.status !== RequestStatus.LOADING}
							isActive={activeRobotVersion === 'draft'}
							version='draft'
							onClickFunction={() => dispatch(changeActiveRobotVersion('draft'))}
						/>
						{robotInfo.hasProd &&
							<Version
								isAvailable={activeRobotId !== null && activeRobotVersion !== 'prod' && robotInfo.status !== RequestStatus.LOADING}
								isActive={activeRobotVersion === 'prod'}
								version='prod'
								onClickFunction={() => dispatch(changeActiveRobotVersion('prod'))}
							/>
						}
						{robotInfo.hasBackup &&
							<Version
								isAvailable={activeRobotId !== null && activeRobotVersion !== 'backup' && robotInfo.status !== RequestStatus.LOADING}
								isActive={activeRobotVersion === 'backup'}
								version='backup'
								onClickFunction={() => dispatch(changeActiveRobotVersion('backup'))}
							/>
						}
					</div>
				}
				rightSection={
					<>
						<div>
							{isAccess(SES.ROBOT_EXPORT) &&
								<Export
									isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE}
									selectDataResponse={selectExportRobot}
									clearDataResponse={clearExportRobot}
									submitHandler={exportHandler}
								/>
							}
							{isAccess(SES.ROBOT_IMPORT) &&
								<Import
									isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE && activeRobotVersion === 'draft'}
									selectDataResponse={selectImportRobot}
									clearDataResponse={clearImportRobot}
									placeOfImport='robot'
									serviceType='ses'
								/>
							}
						</div>
						<div>
							{isAccess(SES.ROBOT_CLEAR_DRAFT) &&
								<ClearDraftRobot
									isAvailable={activeRobotId !== null && activeRobotVersion === 'draft' && robotInfo.hasProd && robotInfo.status === RequestStatus.IDLE}
								/>
							}
							{isAccess(SES.ROBOT_APPLY) &&
								<Apply
									isAvailable={activeRobotId !== null && activeRobotVersion === 'draft' && robotInfo.status === RequestStatus.IDLE}
									dataResponse={selectApplyingRobot}
									clearDataResponse={clearApplyingRobot}
									submitHandler={applyRobotHandler}
									name={robotInfo.data?.name || ''}
									dialogTitle='dialog_applyRobot'
									dialogConfirm='dialog_applyRobotConfirm'
								/>
							}
							{isAccess(SES.ROBOT_RESTORE) &&
								<Restore
									isAvailable={activeRobotId !== null && activeRobotVersion === 'backup' && robotInfo.status === RequestStatus.IDLE}
									dataResponse={selectRestorationRobot}
									clearDataResponse={clearRestorationRobot}
									submitHandler={restoreRobotHandler}
									name={robotInfo.data?.name || ''}
									dialogTitle='dialog_restoreRobot'
									dialogConfirm='dialog_restoreRobotConfirm'
								/>
							}
						</div>
						<div>
							{isAccess(SES.ROBOT_COMMIT_CONFIG) &&
								<Apply
									isAvailable={activeRobotId !== null && robotInfo.status === RequestStatus.IDLE}
									dataResponse={selectCommitConfigRobot}
									clearDataResponse={clearCommitConfigRobot}
									submitHandler={applyConfigHandler}
									name={robotInfo.data?.name || ''}
									buttonTitle='buttonTitle_applyConfigRobot'
									buttonIcon={faPlugCircleCheck}
									dialogTitle='dialog_applyRobotConfig'
									dialogConfirm='dialog_applyRobotConfigConfirm'
								/>
							}
						</div>
					</>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление об изменении данных робота */}
			{showNotificationEditRobot &&
				<Notification
					showNotification={showNotificationEditRobot}
					setShowNotification={setShowNotificationEditRobot}
					selectDataResponse={selectEditingRobot}
					clearDataResponse={clearEditingRobot}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении робота */}
			{showNotificationDelRobot &&
				<Notification
					showNotification={showNotificationDelRobot}
					setShowNotification={setShowNotificationDelRobot}
					selectDataResponse={selectDeletingRobot}
					clearDataResponse={clearDeletingRobot}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении элемента данных */}
			{showNotificationAddDataElem &&
				<Notification
					showNotification={showNotificationAddDataElem}
					setShowNotification={setShowNotificationAddDataElem}
					selectDataResponse={selectAddingData}
					clearDataResponse={clearAddingData}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении элемента данных */}
			{showNotificationEditDataElem &&
				<Notification
					showNotification={showNotificationEditDataElem}
					setShowNotification={setShowNotificationEditDataElem}
					selectDataResponse={selectEditingData}
					clearDataResponse={clearEditingData}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении элемента данных */}
			{showNotificationDelDataElem &&
				<Notification
					showNotification={showNotificationDelDataElem}
					setShowNotification={setShowNotificationDelDataElem}
					selectDataResponse={selectDeletingData}
					clearDataResponse={clearDeletingData}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении конечной точки */}
			{showNotificationAddEndpoint &&
				<Notification
					showNotification={showNotificationAddEndpoint}
					setShowNotification={setShowNotificationAddEndpoint}
					selectDataResponse={selectAddingEndpoint}
					clearDataResponse={clearAddingEndpoint}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении конечной точки */}
			{showNotificationEditEndpoint &&
				<Notification
					showNotification={showNotificationEditEndpoint}
					setShowNotification={setShowNotificationEditEndpoint}
					selectDataResponse={selectEditingEndpoint}
					clearDataResponse={clearEditingEndpoint}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении конечной точки */}
			{showNotificationDelEndpoint &&
				<Notification
					showNotification={showNotificationDelEndpoint}
					setShowNotification={setShowNotificationDelEndpoint}
					selectDataResponse={selectDeletingEndpoint}
					clearDataResponse={clearDeletingEndpoint}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{/* уведомление о добавлении скрипта */}
			{showNotificationAddScript &&
				<Notification
					showNotification={showNotificationAddScript}
					setShowNotification={setShowNotificationAddScript}
					selectDataResponse={selectAddingScript}
					clearDataResponse={clearAddingScript}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{/* уведомление об изменении скрипта */}
			{showNotificationEditScript &&
				<Notification
					showNotification={showNotificationEditScript}
					setShowNotification={setShowNotificationEditScript}
					selectDataResponse={selectEditingScript}
					clearDataResponse={clearEditingScript}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление об удалении скрипта */}
			{showNotificationDelScript &&
				<Notification
					showNotification={showNotificationDelScript}
					setShowNotification={setShowNotificationDelScript}
					selectDataResponse={selectDeletingScript}
					clearDataResponse={clearDeletingScript}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
		</>
	);
};

export default RobotsControls;
