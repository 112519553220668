import { LiveAudioVisualizer } from 'react-audio-visualize';
import { colorSecondary } from '../../constants/colors';
import styles from './AudioVisualizer.module.scss';

const AudioVisualizer = ({ mediaRecorder }: { mediaRecorder?: MediaRecorder }): JSX.Element => {

	return (
		<div className={styles.container}>
			{mediaRecorder && (
				<LiveAudioVisualizer
					mediaRecorder={mediaRecorder}
					width={200}
					height={32}
					barWidth={1}
					gap={0}
					barColor={colorSecondary}
					maxDecibels={0}
				/>
			)}
		</div>
	);
};

export default AudioVisualizer;
