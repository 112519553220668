import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearExportResponseCorpus, clearPutResponseCorpus, exportCorpus, putCorpus, selectCorpus, selectCorpusExportStatus, selectCorpusPutStatus } from '../../../store/corpusSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { CORPUS } from '../../../constants/accessRights';
import Controls from '../../../HOC/Controls/Controls';
import Export from '../Buttons/Export/Export';
import ImportCorpus from '../Buttons/ImportCorpus/ImportCorpus';
import Copy from '../Buttons/Copy/Copy';
import Save from '../Buttons/Save/Save';
import Generating from '../Buttons/Generating/Generating';
import { RequestStatus } from '../../../types/statusTypes';
import { ICorpusControlProps } from './CorpusControl.props';

const CorpusControl = ({ changeFlg, setChangeFlg, serviceType, setShowPage }: ICorpusControlProps) => {
	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик экспортирования корпуса
	const exportHandler = () => {
		corpus.corpusName && dispatch(exportCorpus({ corpusName: corpus.corpusName, serviceType })); // экспорт корпуса данных
	};

	// обработчик сохранения корпуса
	const changeHandler = (): void => {
		// если есть не сохраненные изменения и не загрузка корпуса
		if (changeFlg && corpus.status !== RequestStatus.LOADING) {
			corpus.corpusName && dispatch(putCorpus({ corpusName: corpus.corpusName, text: corpus.data, serviceType })); // сохранение (изменение) корпуса данных
		}
	};

	return (
		<Controls
			header={serviceType}
			setShowPage={setShowPage}
			rightSection={
				<>
					<div>
						{isAccess(CORPUS.EXPORT) &&
							<Export
								isAvailable={corpus.status === RequestStatus.IDLE && corpus.corpusName !== null && Array.isArray(corpus.data.data)}
								selectDataResponse={selectCorpusExportStatus}
								clearDataResponse={clearExportResponseCorpus}
								submitHandler={exportHandler}
							/>
						}
						{isAccess(CORPUS.IMPORT) &&
							<ImportCorpus
								isAvailable={corpus.status === RequestStatus.IDLE && corpus.corpusName !== null && Array.isArray(corpus.data.data)}
								serviceType={serviceType}
								setChangeFlg={setChangeFlg}
							/>
						}
					</div>
					<div>
						{isAccess([CORPUS.AUTOMARK_START, CORPUS.AUTOMARK_STOP, CORPUS.AUTOMARK_STATUS]) && serviceType === 'smc' &&
							<Generating
								isAvailable={corpus.status === RequestStatus.IDLE && corpus.corpusName !== null}
								generate='automark'
							/>
						}
						{isAccess(CORPUS.COPY) &&
							<Copy
								isAvailable={corpus.status === RequestStatus.IDLE && !changeFlg && corpus.corpusName !== null}
								serviceType={serviceType}
							/>
						}
						{isAccess(CORPUS.SAVE) &&
							<Save
								changeFlg={changeFlg}
								dataResponse={selectCorpusPutStatus}
								clearDataResponse={clearPutResponseCorpus}
								submitHandler={changeHandler}
								name={corpus.corpusName || ''}
								dialogTitle='dialog_saveCorpus'
								dialogConfirm='dialog_saveCorpusConfirm'
							/>
						}
					</div>
				</>
			}
		/>
	);
};

export default CorpusControl;
